import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import mattressIpsum from 'mattress-ipsum';
import { Collapse, Button } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "collapse"
    }}>{`Collapse`}</h1>
    <p>{`Collapse creates a smooth transition to hide and show content`}</p>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <p>{`React transition group is needed for the collapse`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add react-transition-group
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Collapse from 'mfcl/Collapse'
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <h4 {...{
      "id": "collapse-1"
    }}>{`Collapse`}</h4>
    <Props of={Collapse} mdxType="Props" />
    <h2 {...{
      "id": "controlled-collapse"
    }}>{`Controlled Collapse`}</h2>
    <Playground __position={1} __code={'<Component initialState={{ isOpen: false }}>\n  {({ setState, state }) => {\n    return (\n      <>\n        <Button onClick={() => setState({ isOpen: !state.isOpen })}>\n          Click Me\n        </Button>\n        <Collapse isOpen={state.isOpen}>{mattressIpsum()}</Collapse>\n      </>\n    )\n  }}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Collapse,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        isOpen: false
      }} mdxType="Component">
    {({
          setState,
          state
        }) => {
          return <>
          <Button onClick={() => setState({
              isOpen: !state.isOpen
            })} mdxType="Button">Click Me</Button>
          <Collapse isOpen={state.isOpen} mdxType="Collapse">{mattressIpsum()}</Collapse>
        </>;
        }}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      